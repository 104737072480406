import React from "react"
import Birthday from "../../components/body/pages/incentive-program/birthday"
import NoheaderFooter from "../../components/Noheaderfooter"
import SEO from "../../components/seo"

const BirthdayRoute = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/incentive-program/birthday"}
      title="Kuda Incentive Program"
    />
    <Birthday />
  </NoheaderFooter>
)
export default BirthdayRoute
